<template>
  <div>
    <div class="top_div" @click="goBack">
      <i class="el-icon-arrow-left left_link"></i>
    </div>
    <img class="top_image" src="@/assets/home_top.jpg" />
    <div v-loading="listLoading" class="list_content">
      <div class="title">热门推荐</div>
      <div v-for="item in list" :key="item.id" class="item">
        <img class="item_left" :src="item.picture" />
        <div class="item_right">
          <div class="item_title">{{ item.name }}</div>
          <div class="item_summary">{{ item.summary }}</div>
          <div class="item_btn_div">
            <div class="start_btn" @click="start(item)">立即体验</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/wenjuan";
export default {
  name: "Index",
  data() {
    return {
      uid: null,
      listLoading: false,
      list: [],
    };
  },
  mounted() {
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
      this.fetchList();
    } else {
      this.$router.push({
        path: "choose_patient",
      });
    }
  },
  methods: {
    //点击顶部的返回按钮
    goBack() {
      this.$router.push({
        path: "choose_patient",
      });
    },
    async start(item) {
      await this.$router.push({
        path: "inform",
        query: { uid: this.uid, type: item.identify, wenjuan_id: item.id },
      });
    },
    async fetchList() {
      this.listLoading = true;
      try {
        const {
          data: { list },
        } = await getList(this.queryForm);
        this.list = list;
      } finally {
        this.listLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top_div {
  position: absolute;
  top: 10px;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
}
.left_link {
  margin-left: 15px;
}
.top_image {
  width: 100%;
  height: 392px;
}
.list_content {
  padding: 36px;
}
.title {
  margin-top: 4px;
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  color: #101010;
}
.item {
  display: flex;
  width: 946px;
  height: 255px;
  margin: 19px auto;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 9px 0px rgba(34, 36, 36, 0.18);
}
.item_left {
  width: 358px;
  height: 254px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.item_right {
  position: relative;
  flex: 1;
  padding-top: 26px;
  padding-left: 26px;
}
.item_title {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  color: #101010;
}
.item_summary {
  display: -webkit-box;
  margin-top: 7px;
  overflow: hidden;
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
  color: #878787;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 20px;
}
.item_btn_div {
  position: absolute;
  right: 40px;
  bottom: 19px;
}
.start_btn {
  width: 200px;
  height: 68px;
  font-size: 30px;
  font-weight: 400;
  line-height: 68px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #078599;
  border-radius: 34px;
}
</style>
